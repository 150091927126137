import revive_payload_client_4sVQNw7RlN from "/srv/portal/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/srv/portal/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/srv/portal/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/srv/portal/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/srv/portal/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/srv/portal/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/srv/portal/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/srv/portal/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _08_errorHandler_GTsQJZXG1S from "/srv/portal/plugins/08.errorHandler.js";
import _09_maskInput_H0dQwPNHyD from "/srv/portal/plugins/09.maskInput.js";
import _10_extensions_ruN2LGCP0g from "/srv/portal/plugins/10.extensions.js";
import _20_init_NSVjw1vMZK from "/srv/portal/plugins/20.init.js";
import _30_dom_client_S2crgAlmCL from "/srv/portal/plugins/30.dom.client.js";
import _40_clickOutside_t17MyWRRTq from "/srv/portal/plugins/40.clickOutside.js";
import _50_marquee_client_uYJp2y1F95 from "/srv/portal/plugins/50.marquee.client.js";
import _60_device_d0iuEcSSTK from "/srv/portal/plugins/60.device.js";
import _70_gtm_client_z4cGDlkBq0 from "/srv/portal/plugins/70.gtm.client.js";
import _80_versionChecker_client_j6ILIBx8Tj from "/srv/portal/plugins/80.versionChecker.client.js";
import _90_teams_client_ZHLUxdscYH from "/srv/portal/plugins/90.teams.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  _08_errorHandler_GTsQJZXG1S,
  _09_maskInput_H0dQwPNHyD,
  _10_extensions_ruN2LGCP0g,
  _20_init_NSVjw1vMZK,
  _30_dom_client_S2crgAlmCL,
  _40_clickOutside_t17MyWRRTq,
  _50_marquee_client_uYJp2y1F95,
  _60_device_d0iuEcSSTK,
  _70_gtm_client_z4cGDlkBq0,
  _80_versionChecker_client_j6ILIBx8Tj,
  _90_teams_client_ZHLUxdscYH
]